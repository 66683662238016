
export function useFooterOrganismComponentController(props) {

    const footerSections = [
        {
            title: 'Lodgr',
            links: [
                { name: 'home', text: 'Home', href: '/', external: false },
                // { name: 'about-us', text: 'About Us', href: '/about-us', external: false },
                { name: 'faq', text: 'FAQ', href: '/faq/general', external: false },
            ],
        },
        {
            title: 'FAQ',
            links: [
                { name: 'general', text: 'General', href: '/faq/general', external: false },
                { name: 'bookings', text: 'Bookings', href: '/faq/bookings', external: false },
                { name: 'payments-and-fees', text: 'Payments & Fees', href: '/faq/pricing', external: false },
            ],
        },
        {
            title: '© 2022',
            links: [
                { name: 'terms-and-conditions', text: 'Terms & Conditions', href: '/tos', external: false },
                { name: 'privacy-policy', text: 'Privacy Policy', href: '/privacy-policy', external: false },
                { name: 'contact', text: 'Contact', href: '/', external: false },
            ],
        },
    ];

    return { footerSections };

}